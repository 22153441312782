<template>
  <g :fill="color" fill-rule="nonzero" transform="translate(-5, -7) scale(0.042, 0.042)">
    <path
      id="moon-o"
      :fill="color"
      d="M526.43,497.63c-8.7,1.44-17.5,2.16-26.32,2.16-28.42.19-56.36-7.27-80.9-21.6-49.91-28.81-80.62-82.1-80.51-139.73-.1-30.38,8.57-60.14,24.97-85.7-31.57,9.22-59.25,28.53-78.79,54.98-20.21,26.45-30.98,58.9-30.62,92.18-.05,20.52,4.13,40.83,12.28,59.66,15.37,36.98,44.76,66.36,81.74,81.74,18.83,8.15,39.14,12.33,59.66,12.28,22.72.08,45.16-4.97,65.66-14.76,20.43-9.67,38.47-23.74,52.82-41.2ZM575.16,477.23c-14.64,32.03-38.29,59.09-68.07,77.89-51,32.88-114.99,38.33-170.81,14.53-44.45-18.49-79.78-53.82-98.27-98.27-9.69-22.57-14.64-46.89-14.55-71.46-.08-99.13,78.34-180.53,177.41-184.15,6.48-.69,12.56,3.2,14.65,9.37,3.03,5.89,1.53,13.09-3.6,17.28-13.51,12.11-24.27,26.97-31.59,43.57-7.27,16.53-10.99,34.4-10.91,52.45-.17,23.03,5.89,45.68,17.55,65.54,11.41,19.73,27.81,36.12,47.54,47.54,36.82,21.33,81.74,23.29,120.27,5.26,5.8-3.02,12.91-1.73,17.28,3.12,2.22,2.21,3.69,5.07,4.19,8.16.58,3.08.2,6.26-1.07,9.12l-.04.02Z"
    />
  </g>
</template>
<script>
export default {
  props: {
    color: {
      type: String,
      default: 'currentColor',
    },
  },
};
</script>
